import {
  Body,
  BodyMedium16Css,
  BodyMedium24Css,
  H2,
  H5,
  H5Css,
  H9,
  Heading,
} from "@/components/Typography";
import reviewBackgroundIcon from "../../public/images/homepage/review-background-icon.png";
import { css } from "@emotion/react";
import Image from "next/image";
import { GetStaticProps } from "next";
import Layout from "@/components/Layout/Layout";
import Link from "next/link";
import {
  darkColor,
  lightBlueColor,
  mutedBlueColor,
  textColor,
} from "@/constants/colors";
import { homepageFeatures1, homepageFeatures2 } from "@/constants/homepageData";
import HomepageFeatureCard from "@/components/Homepage/HomepageFeatureCard";
import navbarData from "@/constants/navbarData";
import Sport from "@/components/Homepage/Sport";
import ReviewSiteButton from "@/components/ReviewSiteButton/ReviewSiteButton";
import Balancer from "react-wrap-balancer";
import { demoPath } from "@/constants/paths";
import { mq } from "@/constants/breakpoints";
import HomepageHeroJoin from "@/components/Homepage/HomepageHeroJoin";
import Container from "@/components/Container/Container";
import Testimonials from "@/components/Testimonial/Testimonials";
import BottomCTA from "@/components/BottomCTA/BottomCTA";
import { getHomepage } from "@/utils/getHomepage";
import trophyIllustration from "../../public/images/homepage/trophy-illustration.svg";
import HomepageHeroTestimonials from "@/components/Homepage/HomepageHeroTestimonials";
import { useWindowSize } from "@/hooks/useWindowSize";

// import HomepageHeroImageXs from "../../public/images/homepage/hero_xs.png";
import HomepageHeroImageSm from "../../public/images/homepage/hero_sm.png";
import HomepageHeroImageMd from "../../public/images/homepage/hero_md.png";
import HomepageHeroImageLg from "../../public/images/homepage/hero_lg.png";
import HomepageHeroImageXl from "../../public/images/homepage/hero_xl.png";
import HomepageHeroImageXl2 from "../../public/images/homepage/hero_xl2.png";
import { ReviewBadgeHero } from "@/components/Homepage/ReviewBadgeHero";
import { GetStartedButtonHero } from "@/components/Homepage/GetStartedButtonHero";
import PlayButton from "@/svg/PlayButton";

// const WISTIA_ID = "ylz2kajhot";

const Page = ({ fields }: any) => {
  const { isXs, isSm, isMd, isLg, isXl, isXl2 } = useWindowSize();
  // const [video, setVideo] = useState<any>(null);

  // useEffect(() => {
  //   // @ts-ignore
  //   window._wq = window._wq || [];
  //   // @ts-ignore
  //   window._wq.push({
  //     id: WISTIA_ID,
  //     onReady: function (returnVideo: any) {
  //       setVideo(returnVideo);
  //     },
  //   });
  // }, []);
  // HOOKS END

  // const handleClick = () => {
  //   try {
  //     video.popover.show();
  //     video.play();
  //     // const event = 'Played Explainer Video';
  //     // window.heap?.track(event);
  //   } catch (e) {
  //     console.log(e);
  //     // Sentry.captureException(e);
  //   }
  // };

  return (
    <>
      {/* <Script
        src="https://fast.wistia.com/assets/external/E-v1.js"
        strategy="lazyOnload"
      /> */}
      <Layout
        title={fields.pageTitle}
        metaDescription={fields.metaDescription}
        heroContainerCss={css`
          background-image: url("/images/homepage/BG.svg"),
            linear-gradient(112.24deg, #048cdd 4.51%, #32b2ff 100.81%);
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          z-index: 1;
        `}
        hero={
          <Container>
            <div
              css={css`
                text-align: center;
                margin-top: 16px;
                color: #fff;
                display: flex;
                flex-direction: column;
                ${mq["sm"]} {
                  margin-top: 32px;
                }
                ${mq["lg"]} {
                  margin-top: 19px;
                  flex-direction: row;
                }
                ${mq["xl3"]} {
                  margin-left: -144px;
                  margin-right: -144px;
                }
              `}
            >
              <div
                css={css`
                  ${mq["md"]} {
                    margin-top: 0px;
                  }
                  ${mq["lg"]} {
                    flex: 2;
                    margin-top: 30px;
                    text-align: left;
                  }
                  ${mq["xl3"]} {
                    flex: 1;
                  }
                `}
              >
                <Heading
                  level={1}
                  css={css`
                    max-width: 1200px;
                    margin: auto;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 130%;
                    ${mq["lg"]} {
                      font-size: 56px;
                      line-height: 120%;
                      margin: 0;
                    }
                  `}
                >
                  <Balancer
                    dangerouslySetInnerHTML={{
                      __html: fields.heroHeading.replace(/\. /, ".<br>"),
                    }}
                  ></Balancer>
                </Heading>
                <Body
                  css={css`
                    max-width: 925px;
                    margin: 14px auto 0;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                    ${mq["lg"]} {
                      margin: 24px 0 0;
                      font-weight: 500;
                      font-size: 20px;
                    }
                  `}
                >
                  <Balancer>{fields.heroSubheading}</Balancer>
                </Body>

                {(isLg || isXl || isXl2) && (
                  <div
                    css={css`
                      margin-top: 41px;
                      justify-content: flex-start;
                    `}
                  >
                    <GetStartedButtonHero disclaimer="No credit card required" />
                  </div>
                )}
                <div
                  css={css`
                    display: flex;
                    gap: 12px;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 24px;
                    margin-top: 24px;
                    ${mq["sm"]} {
                      gap: 0px;
                      margin-top: 16px;
                      flex-direction: column;
                      align-items: center;
                    }
                    ${mq["md"]} {
                      margin-top: 48px;
                      flex-direction: row;
                      align-items: center;
                      justify-content: center;
                      margin-bottom: 50px;
                      ${mq["lg"]} {
                        margin-bottom: 20px;
                      }
                    }
                    ${mq["lg"]} {
                      flex-wrap: nowrap;
                      justify-content: flex-start;
                    }
                  `}
                >
                  <div
                    css={css`
                      flex-direction: column;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        justify-content: center;
                        gap: 4px;
                        width: 100%;
                        ${mq["lg"]} {
                          justify-content: flex-start;
                        }
                        ${mq["md"]} {
                          gap: 16px;
                        }
                        align-items: center;
                      `}
                    >
                      <ReviewBadgeHero
                        imageSrc="/images/icons/g2_icon.svg"
                        text={`4.8 Stars ${process.env.g2Count}+ ${
                          isXs ? "" : "reviews"
                        }`}
                      />

                      {isMd && (
                        <div
                          css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}
                        >
                          <GetStartedButtonHero />
                        </div>
                      )}

                      <ReviewBadgeHero
                        imageSrc="/images/icons/trustpilot_icon.svg"
                        text={`5.0 Stars ${process.env.trustpilotCount}+ ${
                          isXs ? "" : "reviews"
                        }`}
                      />
                    </div>
                    {isXs ||
                      (isMd && (
                        <Body
                          css={css`
                            margin-top: 8px;
                            opacity: 70%;
                          `}
                        >
                          No credit card required
                        </Body>
                      ))}
                  </div>

                  {(isXs || isSm) && (
                    <div
                      css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        ${mq["xs"]} {
                          width: 100%;
                          a {
                            width: 100%;
                          }
                        }
                        ${mq["sm"]} {
                          width: auto;
                          margin-top: 50px;
                        }
                      `}
                    >
                      <GetStartedButtonHero disclaimer="No credit card required" />
                    </div>
                  )}
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  ${mq["lg"]} {
                    margin-left: 100px;
                  }
                  ${mq["xl2"]} {
                    margin-left: unset;
                    height: 100%;
                  }
                  @media (max-width: 768px) {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 32px;
                  }
                  ${mq["lg"]} {
                    margin-top: 0;
                  }
                `}
              >
                {/* container for the hero image used to show blurred padding */}
                <div
                  css={css`
                    position: relative;
                    width: 100%;
                    box-shadow: -100px 100px 150px 0px #3440541f;
                    border: 1px solid #ffffff33;
                    background: linear-gradient(
                      103.92deg,
                      rgba(255, 255, 255, 0.1) -0.74%,
                      rgba(190, 220, 255, 0.1) 99.87%
                    );
                    backdrop-filter: blur(78px);
                    padding: 12px;
                    border-radius: 40px;
                    ${mq["xs"]} {
                      display: none;
                      padding: 0;
                    }
                    ${mq["sm"]} {
                      display: flex;
                      padding: 24px;
                    }
                    ${mq["lg"]} {
                      position: absolute;
                      width: 50%;
                      right: -180px !important;
                      padding: 24px 0px 24px 20px;
                      border-radius: 40px 0 0 40px;
                    }
                    ${mq["xl"]} {
                      right: -70px;
                    }
                    ${mq["xl3"]} {
                      position: relative;
                      padding: 24px;
                      border-radius: 40px;
                      width: 100%;
                      right: unset !important;
                    }
                    @media (max-width: 768px) {
                      position: relative;
                      width: calc(100% + 20px);
                      margin-left: -20px;
                      margin-right: -20px;
                      height: 100%;
                    }
                  `}
                >
                  <Image
                    src={HomepageHeroImageSm}
                    priority
                    width={1188}
                    height={760}
                    alt="Homepage hero image"
                    css={css`
                      display: none;
                      ${mq["sm"]} {
                        display: block;
                        width: 100%;
                        height: auto;
                      }
                      ${mq["md"]} {
                        display: none;
                      }
                    `}
                  />
                  <Image
                    src={HomepageHeroImageMd}
                    priority
                    width={1300}
                    height={831}
                    alt="Homepage hero image"
                    css={css`
                      border-radius: 16px;
                      display: none;
                      width: 100%;

                      ${mq["md"]} {
                        display: block;
                        width: 100%;
                        height: auto;
                      }
                      ${mq["lg"]} {
                        display: none;
                      }
                    `}
                  />
                  <Image
                    src={HomepageHeroImageLg}
                    priority
                    width={880}
                    height={972}
                    alt="Homepage hero image"
                    css={css`
                      border-radius: 16px;
                      display: none;
                      ${mq["lg"]} {
                        display: block;
                        height: 486px;
                        width: 100%;
                        object-fit: cover;
                        object-position: top left;
                      }
                      ${mq["xl"]} {
                        display: none;
                      }
                    `}
                  />
                  <Image
                    src={HomepageHeroImageXl}
                    priority
                    width={1088}
                    height={972}
                    alt="Homepage hero image"
                    css={css`
                      border-radius: 16px;
                      display: none;
                      ${mq["xl"]} {
                        display: block;
                        height: 486px;
                        width: 100%;
                        object-fit: cover;
                        object-position: top left;
                      }
                      ${mq["xl2"]} {
                        display: none;
                      }
                    `}
                  />
                  <Image
                    src={HomepageHeroImageXl2}
                    priority
                    width={1292}
                    height={972}
                    alt="Homepage hero image"
                    css={css`
                      border-radius: 16px;
                      display: none;
                      ${mq["xl2"]} {
                        display: block;
                        height: 486px;
                        width: 760px;
                        object-fit: cover;
                        object-position: top left;
                      }
                    `}
                  />
                  {/* {video && ( */}

                  <Link href={demoPath}>
                    <PlayButton
                      // onClick={null}
                      role="button"
                      css={css`
                        display: none;
                        ${mq["sm"]} {
                          display: block;
                        }
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                      `}
                    />
                  </Link>
                  {/* )} */}
                </div>
              </div>
            </div>
            <div
              css={css`
                position: relative;
                width: 100%;
                @media (min-width: 640px) and (max-width: 1023px) {
                  height: 80px;
                }
                ${mq["xl3"]} {
                  margin-left: -144px;
                  margin-right: -144px;
                  min-width: 1480px;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-grow: 1;
                  flex-direction: column;
                  position: relative;
                  @media (min-width: 640px) and (max-width: 1023px) {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    margin-top: -15%;
                    width: 100vw;
                    margin-left: calc(-50vw + 50%);
                    padding-left: 16px;
                    padding-top: 20px;
                    z-index: 10;
                    background: linear-gradient(
                      180deg,
                      rgba(4, 140, 221, 0) 0%,
                      #048cdd 14%
                    );
                  }
                `}
              >
                <div
                  css={css`
                    color: white;
                    ${mq["xs"]} {
                      padding-top: 0px;
                    }
                    ${mq["sm"]} {
                      padding-top: 12px;
                    }

                    ${mq["lg"]} {
                      margin-top: 24px;
                      padding-top: 0px;
                    }
                  `}
                >
                  <HomepageHeroJoin />
                </div>
                <div
                  css={css`
                    margin-bottom: 24px;
                    margin-right: calc(-1 * (100vw - 100%) / 2);
                    ${mq["sm"]} {
                      margin-bottom: 40px;
                    }
                    ${mq["xl3"]} {
                      margin-right: unset;
                    }
                  `}
                >
                  <HomepageHeroTestimonials
                    testimonials={fields.testimonialsHeroCollection.items}
                  />
                </div>
              </div>
            </div>
          </Container>
        }
      >
        <div
          css={css`
            background-color: ${mutedBlueColor};
          `}
        >
          <Container
            css={css`
              text-align: center;
              position: relative;
              padding-top: 60px;
              padding-bottom: 206px;
              ${mq["sm"]} {
                text-align: left;
                padding-top: 40px;
                padding-bottom: 40px;
              }
              ${mq["lg"]} {
                padding-top: 75px;
                padding-bottom: 113px;
              }
            `}
          >
            <div
              css={css`
                ${mq["sm"]} {
                  max-width: 350px;
                }
                ${mq["md"]} {
                  max-width: 510px;
                }
                ${mq["lg"]} {
                  max-width: 584px;
                }
              `}
            >
              <Heading
                level={3}
                css={css`
                  color: ${darkColor};
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 140%;
                  ${mq["lg"]} {
                    font-size: 24px;
                  }
                `}
              >
                <Balancer>
                  The all-in-one platform to take your sports organization
                </Balancer>
              </Heading>
              <Heading
                level={3}
                css={css`
                  margin-top: 8px;
                  font-weight: 400;
                  font-size: 28px;
                  color: ${darkColor};
                  ${mq["lg"]} {
                    font-size: 56px;
                  }
                `}
              >
                to the{" "}
                <span
                  css={css`
                    color: ${lightBlueColor};
                  `}
                >
                  next level.
                </span>
              </Heading>
              <H9
                css={css`
                  margin-top: 24px;
                  color: ${textColor};
                  ${mq["lg"]} {
                    font-weight: 350;
                    font-size: 18px;
                  }
                `}
              >
                <Balancer>
                  Build a website, manage registration, send event
                  notifications, and more!
                </Balancer>
              </H9>
            </div>
            <Image
              src={trophyIllustration}
              width={568}
              height={568}
              alt="Trophy Illustration"
              css={css`
                position: absolute;
                z-index: 0;
                bottom: -20px;
                margin: auto;
                right: 0;
                left: 0;
                height: 244px;
                width: auto;
                ${mq["sm"]} {
                  top: 0;
                  left: unset;
                  height: 317px;
                  bottom: 0;
                  width: auto;
                  margin: auto 0;
                }
                ${mq["md"]} {
                  height: 248px;
                }
                ${mq["lg"]} {
                  height: 568px;
                }
              `}
            />
          </Container>
        </div>
        <div
          css={css`
            background-color: #fff;
            position: relative;
          `}
        >
          <Container>
            {homepageFeatures1.map(
              ({ title, description, tag, href, imageSrc }, index) => {
                return (
                  <HomepageFeatureCard
                    index={index}
                    title={title}
                    description={description}
                    imageSrcMobile={imageSrc}
                    tag={tag}
                    href={href}
                    key={index}
                  />
                );
              }
            )}
            <H5
              css={css`
                color: ${darkColor};
                padding: 60px 0 0;
              `}
            >
              And more...
            </H5>
            <div
              css={css`
                ${mq["lg"]} {
                  display: flex;
                  gap: 40px;
                }
              `}
            >
              {homepageFeatures2.map(
                (
                  { title, description, imageSrcDesktop, imageSrcMobile, href },
                  index
                ) => (
                  <HomepageFeatureCard
                    index={index}
                    key={index}
                    title={title}
                    description={description}
                    imageSrcDesktop={imageSrcDesktop}
                    imageSrcMobile={imageSrcMobile}
                    href={href}
                  />
                )
              )}
            </div>
          </Container>
          <div
            css={css`
              padding-top: 60px;
            `}
          />
        </div>
        <div
          css={css`
            padding: 50px 0 50px;
            background: url(/images/homepage/sports-bg.svg),
              linear-gradient(112.24deg, #048cdd 4.51%, #32b2ff 100.81%);
            background-size: cover;
            background-repeat: no-repeat;
            ${mq["lg"]} {
              padding: 100px 0 100px;
            }
          `}
        >
          <Container>
            <Heading
              level={3}
              css={css`
                color: #fff;
                text-align: center;
                padding-bottom: 50px;
                ${H5Css}
                ${mq["lg"]} {
                  padding-bottom: 80px;
                  font-size: 50px;
                }
              `}
            >
              Made to help your sports organization grow!
            </Heading>
            <div
              css={css`
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;
                ${mq["sm"]} {
                  grid-template-columns: repeat(3, 1fr);
                }
                ${mq["lg"]} {
                  grid-template-columns: repeat(4, 1fr);
                }
              `}
            >
              {navbarData.sports.map(({ title, href, Icon }, index) => (
                <Sport key={index} title={title} href={href} Icon={Icon} />
              ))}
            </div>
          </Container>
        </div>
        <Testimonials testimonials={fields.testimonialsCollection.items} />
        <Container
          css={css`
            padding: 60px 0;
            display: flex;
            flex-direction: column;
            gap: 60px;
            ${mq["md"]} {
              flex-direction: row;
              justify-content: space-between;
            }
          `}
        >
          <div
            css={css`
              position: relative;
              text-align: center;
              ${mq["md"]} {
                text-align: left;
              }
            `}
          >
            <Image
              src={reviewBackgroundIcon}
              alt="Review background icon"
              css={css`
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                width: 218px;
                height: auto;
                ${mq["md"]} {
                  right: -150px;
                  width: 242px;
                }
                ${mq["lg"]} {
                  width: 377px;
                }
              `}
            />
            <H2
              css={css`
                color: ${lightBlueColor};
                padding-top: 40px;
                ${mq["lg"]} {
                  font-weight: 400;
                  font-size: 90px;
                  line-height: 130.02%;
                }
              `}
            >
              {process.env.organizationCount}+
            </H2>
            <Body
              css={css`
                color: ${textColor};
                ${BodyMedium16Css}
                ${mq["lg"]} {
                  ${BodyMedium24Css}
                }
              `}
            >
              <Balancer>
                sports organizations are using Jersey Watch this season
              </Balancer>
            </Body>
          </div>
          <div
            css={css`
              margin: auto;
              display: flex;
              flex-direction: column;
              gap: 15px;
              max-width: 100%;
              ${mq["md"]} {
                margin: unset;
              }
            `}
          >
            <ReviewSiteButton type="g2" />
            <ReviewSiteButton type="trustpilot" />
          </div>
        </Container>
        <BottomCTA />
      </Layout>
      {/* <div
        className={`wistia_embed wistia_async_${WISTIA_ID} popover=true stillUrl=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`}
        style={{ position: "relative", width: 0, height: 0 }}
      /> */}
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const { fields } = await getHomepage();
  return {
    props: { fields }, // will be passed to the page component as props
  };
};

export default Page;
